<template>
  <div class="com-pagi">
    <el-pagination
     background 
     :layout="layout" 
     :page-sizes="pageSizesType?pageSizesType:pageSizes" 
     :page-size="pageSize" 
     :current-page="currentPage" 
     :total="total" 
     @size-change="handleSizeChange" 
     @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pageSizesType: {
      type: Array,
      default: () => []

    },
    total: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pageSizes: [10, 50, 100, 200],
      layout: 'total, sizes,prev, pager, next, jumper',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('handleChange', { page: this.currentPage, limit: val })
    },
    handleCurrentChange(val) {
      this.$emit('handleChange', { page: val, limit: this.pageSize })
    },
  },
}
</script>
<style lang="scss" scoped>
.com-pagi {
  height: 40px;
  margin-top: 10px;
}
</style>
