import { Request } from '@/http/request'

// 号码分组--->列表展示
export const numberGroupList=(parameter: any)=>{
  return Request.axiosInstance.post('/open/number-group/list', parameter)
}
// 号码分组--->下拉列表
export const numberGroupQuery=(parameter: any)=>{
  return Request.axiosInstance.post('/open/number-group/query', parameter)
}
// 号码分组--->添加
export const numberGroupAdd=(parameter: any)=>{
  return Request.axiosInstance.post('/open/number-group/add', parameter)
}
// 号码分组--->编辑
export const numberGroupEdit=(parameter: any)=>{
  return Request.axiosInstance.post('/open/number-group/edit', parameter)
}
// 号码分组--->删除
export const numberGroupDel=(parameter: any)=>{
  return Request.axiosInstance.post('/open/number-group/delete', parameter)
}

// 虚拟号码--->列表展示
export const virtualNumberList=(parameter: any)=>{
  return Request.axiosInstance.post('/open/virtual-numbers/list', parameter)
}
// 虚拟号码--->添加
export const virtualNumberAdd=(parameter: any)=>{
  return Request.axiosInstance.post('/open/virtual-numbers/add', parameter)
}
// 虚拟号码--->批量添加
export const virtualNumberBatchAdd=(parameter: any)=>{
  return Request.axiosInstance.post('/open/virtual-numbers/batch-add', parameter)
}
// 虚拟号码--->删除
export const virtualNumberDel=(parameter: any)=>{
  return Request.axiosInstance.post('/open/virtual-numbers/delete', parameter)
}
// 虚拟号码--->批量删除
export const virtualNumberBatchDel=(parameter: any)=>{
  return Request.axiosInstance.post('/open/virtual-numbers/batch-delete', parameter)
}

// 真实号码--->列表展示
export const realNumberList=(parameter: any)=>{
  return Request.axiosInstance.post('/open/reality-numbers/list', parameter)
}
// 真实号码--->添加
export const realNumberAdd=(parameter: any)=>{
  return Request.axiosInstance.post('/open/reality-numbers/add', parameter)
}
// 真实号码--->批量添加
export const realNumberBatchAdd=(parameter: any)=>{
  return Request.axiosInstance.post('/open/reality-numbers/batch-add', parameter)
}
// 真实号码--->删除
export const realNumberDel=(parameter: any)=>{
  return Request.axiosInstance.post('/open/reality-numbers/delete', parameter)
}
// 真实号码--->批量删除
export const realNumberBatchDel=(parameter: any)=>{
  return Request.axiosInstance.post('/open/reality-numbers/batch-delete', parameter)
}
//获取服务商下的分组信息
export const serviceProviderGroup=(parameter: any)=>{
  return Request.axiosInstance.post('/open/number-group/get-by-amount-id', parameter)
}